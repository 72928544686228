<template>
<div>
    <div class="display-block">
        <page-header v-model="search" title="Inventory" @input="updateApiCall(apiCall,search)" permission='createInventory' @click="navigateTo('/app/inventory/0')"></page-header>
    </div>
    <div>
        <v-data-table :loading="loading" :page="currentPage" @update:page="setCurrentPage($event,lsCurrentPageKey)"
         hide-default-footer :headers="headers" :items="items" class="elevation-1" :search="search" :items-per-page="items.length">
            <template v-slot:item.stock="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-chip small label :color="item.stock <= item.reserveStock ? 'red' : 'green'">{{item.stock}}</v-chip>
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="performAction(icon,item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <inventory-card :productInventory="item" :icons="icons" @delete="removeItem"></inventory-card>
            </template>
        </v-data-table>
        <pagination ref="pagination" :api="apiCall" v-model="items" sort="+title"></pagination>
    </div>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import InventoryCard from '@/components/InventoryResponsiveCard'
export default {
    components: {
        ActionButton,
        InventoryCard
    },
    data() {
        return {
            search: '',
            headers: [{
                    text: "Code",
                    value: "code"
                },
                {
                    text: "Title",
                    value: "title"
                },
                {
                    text: "Price",
                    value: "price",
                    align:'right'
                },
                {
                    text: "Offer Price",
                    value: "offerprice",
                    align:'right'
                },
                {
                    text: "Stock",
                    value: "stock",
                    align:'right'
                },
                {
                    text: "Reserve Stock",
                    value: "reserveStock",
                    align:'right'
                },
                {
                    text: "Action",
                    value: "action",
                    align:'right'
                }
            ],
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/inventory/view/",
                    permission: "viewInventory"
                }],
            lsCurrentPageKey: 'inventory-current-page',
            loading: false,
            apiCall:appConstants.INVENTORY_API
        }
    },
    methods: {
        async deleteFromInventory(id) {
            try {
                await this.deleteItem("Are you sure you want to delete from inventory?", appConstants.INVENTORY_API + "/" + id)
                this.items.splice(this.items.findIndex(rec=>rec._id==id), 1)
            } catch (error) {
                this.handleError(error)
            }
        },
        removeItem(id){
            this.deleteFromInventory(id)
        }
    },
}
</script>

<style scoped>

</style>
