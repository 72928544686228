<template>
<v-layout>
    <v-flex xs8>
        <v-col class="align-card-font">
            <v-row>
                <label class="card-title-font">{{productInventory.title}}</label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font">{{productInventory.code}}</label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font">Rs {{productInventory.price}}</label>
            </v-row>
        </v-col>
    </v-flex>
    <v-flex xs4>
        <v-col class="align-card-font">
            <v-row>
                <label  class="card-subtitle-font">Stock 
                    <v-chip small :color="productInventory.stock < productInventory.reserveStock? 'red' : 'green'">{{productInventory.stock}}</v-chip>
                </label>
            </v-row>
            <v-row>
                <label class="card-subtitle-font">Reserve 
                    <v-chip small :color="productInventory.stock > productInventory.reserveStock? 'red' : 'green'">{{productInventory.reserveStock}}</v-chip>
                </label>
            </v-row>
        </v-col>
    </v-flex>
    <v-menu left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in icons" :key="index">
                <v-btn fab dark x-small class="ma-2" :style="theme">
                    <v-icon :show="isAllowed(item.permission)" @click="performAction(item,productInventory._id)">{{ item.name }}</v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

<script>
export default {
    props: ["productInventory", "icons"],
};
</script>
